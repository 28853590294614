<template>
  <div>
    <v-btn @click="handleExport">
      export
    </v-btn>
		<div v-show="false">
	<table id="tbl1" class="table2excel">
	<thead>
	<tr>

	<th data-style="Header">Product test</th>
	<th data-style="Header">Price</th>
	<th data-style="Header">Available</th>
	<th data-style="Header">Count</th>
	</tr>
	</thead>
	<tbody>
	<tr>
	<td data-style="Changed">Bred</td>
	<td data-style="Missed">1
	</td>
	<td>037.57834600204239857
	</td>
	<td data-style="Header">3
	</td>
	</tr>
	<tr>
	<td>Butter</td>
	<td>4
	</td>
	<td>5
	</td>
	<td >6
	</td>
	</tr>
	</tbody>
	</table>
	<hr>

	<table id="tbl2" class="table2excel">
	<thead>
	<tr>
	<th>Product</th>
	<th>Price</th>
	<th>Available</th>
	<th>Count</th>
	</tr>
	</thead>
	<tbody>
	<tr>
	<td>Bred</td>
	<td>7.5
	</td>
	<td>8
	</td>
	<td>9
	</td>
	</tr>
	<tr>
	<td>Butter</td>
	<td>14
	</td>
	<td>15
	</td>
	<td >16
	</td>
	</tr>
	</tbody>
	</table>

	<table id="joined" class="table2excel">
	<thead>
	<tr></tr>
	</thead>
	<tbody>
	</tbody>
	</table>
		</div>
  </div>
</template>
<script>
export default {
  methods: {
    handleExport () {
      this.tablesToExcel(['tbl1','tbl2'], ['ProductDay1','ProductDay2'], 'TestBook.xls', 'Excel')
    },
    tablesToExcel (tables, wsnames, wbname, appname) {
      var uri = 'data:application/vnd.ms-excel;base64,'
        , tmplWorkbookXML = '<?xml version="1.0" encoding="windows-1252"?><?mso-application progid="Excel.Sheet"?>'
              + '	<Workbook xmlns="urn:schemas-microsoft-com:office:spreadsheet" xmlns:ss="urn:schemas-microsoft-com:office:spreadsheet" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel"  xmlns:html="http://www.w3.org/TR/REC-html40">'
              + '		<DocumentProperties xmlns="urn:schemas-microsoft-com:office:office">'
              + '			<Author>Qompare</Author>'
              + '			<Created>{created}</Created>'
              + '		</DocumentProperties>'
              + '		<Styles>'
              + '			<Style ss:ID="Default" ss:Name="Normal">'
              + '				<NumberFormat ss:Format=""/>'
              + '			</Style>'
              + '			<Style ss:ID="Header">'
              + '				<Alignment ss:Vertical="Bottom"/>'
              + '				<Borders>'
              + '					<Border ss:Color="#000000" ss:Weight="2" ss:LineStyle="Continuous" ss:Position="Right"/>'
              + '					<Border ss:Color="#000000" ss:Weight="2" ss:LineStyle="Continuous" ss:Position="Left"/>'
              + '					<Border ss:Color="#000000" ss:Weight="2" ss:LineStyle="Continuous" ss:Position="Top"/>'
              + '					<Border ss:Color="#000000" ss:Weight="2" ss:LineStyle="Continuous" ss:Position="Bottom"/>'
              + '				</Borders>'
              + '				<Font ss:FontName="Calibri" ss:Size="12" ss:Color="#000000"/>'
              + '				<Interior ss:Color="#cccccc" ss:Pattern="Solid" />'
              + '				<NumberFormat/>'
              + '				<Protection/>'
              + '			</Style>'
              + '			<Style ss:ID="Changed">'
              + '				<Borders/>'
              + '				<Font ss:Color="#ff0000"></Font>'
              + '				<Interior ss:Color="#99CC00" ss:Pattern="Solid"></Interior>'
              + '				<NumberFormat/>'
              + '				<Protection/>'
              + '			</Style>'
              + '			<Style ss:ID="Missed">'
              + '				<Borders/>'
              + '				<Font ss:Color="#ff0000"></Font>'
              + '				<Interior ss:Color="#ff0000" ss:Pattern="Solid"></Interior>'
              + '				<NumberFormat/>'
              + '				<Protection/>'
              + '			</Style>'
              + '			<Style ss:ID="Decimals">'
              + '				<NumberFormat ss:Format="Fixed"/>'
              + '			</Style>'    
              + '	</Styles>' 
              + '	{worksheets}'
              + '</Workbook>'
              , tmplWorksheetXML = '<Worksheet ss:Name="{nameWS}">'
              + '	<ss:Table>'
              + '		{rows}'
              + '	</ss:Table>'
              + '</Worksheet>'
              , tmplCellXML = '			<ss:Cell{attributeStyleID}{attributeFormula}>'
              + '				<ss:Data ss:Type="{nameType}">{data}</ss:Data>'
              + '			</ss:Cell>'
    , base64 = function(s) { return window.btoa(unescape(encodeURIComponent(s))) }
    , format = function(s, c) { return s.replace(/{(\w+)}/g, function(m, p) { return c[p]; }) }

        var ctx = "";
        var workbookXML = "";
        var worksheetsXML = "";
        var rowsXML = "";

        for (var i = 0; i < tables.length; i++) {
          if (!tables[i].nodeType) tables[i] = document.getElementById(tables[i]);
          for (var j = 0; j < tables[i].rows.length; j++) {
            rowsXML += '		<ss:Row>'
            for (var k = 0; k < tables[i].rows[j].cells.length; k++) {
              var dataType = tables[i].rows[j].cells[k].getAttribute("data-type");
              var dataStyle = tables[i].rows[j].cells[k].getAttribute("data-style");
              var dataValue = tables[i].rows[j].cells[k].getAttribute("data-value");
              dataValue = (dataValue)?dataValue:tables[i].rows[j].cells[k].innerHTML;
              if(!isNaN(dataValue)){
                  dataType = 'Number';
                  dataValue = parseFloat(dataValue);
              }
              var dataFormula = tables[i].rows[j].cells[k].getAttribute("data-formula");
              dataFormula = (dataFormula)?dataFormula:(appname=='Calc' && dataType=='DateTime')?dataValue:null;
              ctx = {  attributeStyleID: (dataStyle=='Changed' || dataStyle=='Missed'|| dataStyle=='Header')?' ss:StyleID="'+dataStyle+'"':''
                     , nameType: (dataType=='Number' || dataType=='DateTime' || dataType=='Boolean' || dataType=='Error')?dataType:'String'
                     , data: (dataFormula)?'':dataValue
                     , attributeFormula: (dataFormula)?' ss:Formula="'+dataFormula+'"':''
                    };
              rowsXML += format(tmplCellXML, ctx);
            }
            rowsXML += '		</ss:Row>'
          }
          ctx = {rows: rowsXML, nameWS: wsnames[i] || 'Sheet' + i};
          worksheetsXML += format(tmplWorksheetXML, ctx);
          rowsXML = "";
        }

        ctx = {created: (new Date()).getTime(), worksheets: worksheetsXML};
        workbookXML = format(tmplWorkbookXML, ctx);
        var link = document.createElement("A");
        link.href = uri + base64(workbookXML);
        link.download = wbname || 'Workbook.xls';
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    },
  },
}
</script>

